import {Portal, Box, useDisclosure, Image, Text, Container, SimpleGrid, useColorModeValue, Heading} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
import Navbar from 'components/navbar/NavbarAdmin.js';
import React, {useState} from 'react';
import {jwtDecode} from 'jwt-decode';
import { motion } from 'framer-motion';

// Import images
import userIcon from 'assets/img/user.png';
import warehouseIcon from 'assets/img/warehouse.png';
import dashboardIcon from 'assets/img/dashboard.png';
import teraIcon from 'assets/img/TeraTangki.png';
import blacklistIcon from 'assets/img/blacklist.png';
import nrpIcon from 'assets/img/generatenrp.png';

const MotionBox = motion(Box);
const MotionContainer = motion(Container);

const containerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1
        }
    }
};

const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { 
        y: 0, 
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 100
        }
    }
};

const MenuCard = ({ href, imageSrc, title }) => {
    const bgColor = useColorModeValue('white', 'gray.700');
    const shadowColor = useColorModeValue('rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.4)');

    return (
        <MotionBox
            as="a"
            href={href}
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bg={bgColor}
            p={4}
            borderRadius="xl"
            boxShadow={`0 4px 20px ${shadowColor}`}
            transition="all 0.3s ease"
            height="200px"
            width="200px"
            _hover={{
                transform: "translateY(-5px)",
                boxShadow: `0 8px 25px ${shadowColor}`
            }}
        >
            <Image
                src={imageSrc}
                alt={title}
                height="100px"
                width="100px"
                mb={3}
                objectFit="contain"
            />
            <Text
                fontSize="sm"
                fontWeight="600"
                textAlign="center"
                color={useColorModeValue('gray.700', 'white')}
            >
                {title}
            </Text>
        </MotionBox>
    );
};

export default function Dashboard(props) {
    const {...rest} = props;
    const [fixed] = useState(false);
    const isDevelopment = process.env.REACT_APP_ENV === 'development';
    const apiHost = process.env.REACT_APP_API_HOST;

    const getAuthUrl = (host, hostDev) => {
        if (isDevelopment) {
            return `${hostDev}`;
        }
        return `${host}`;
    };

    function getCookieValue(name) {
        const cookieString = document.cookie
            .split('; ')
            .find((row) => row.startsWith(`${name}=`));
        return cookieString ? cookieString.split('=')[1] : null;
    }

    const token = getCookieValue('token');
    const decoded = token ? jwtDecode(token) : null;

    if (!decoded) {
        console.warn('Token is missing or invalid.');
    }

    const showTeraTangki = decoded?.roles?.includes('tera:web:tbbmkuin:view');
    const showAuth = decoded?.roles?.includes('auth:web:master:view');
    const showWarehouse = decoded?.roles?.includes('warehouse:web:master:view');
    const showDashboard = decoded?.roles?.includes('dashboard:web:master:view');
    const showGeneratedNRP = decoded?.roles?.includes('generated-nrp:web:master:view');
    const showBlacklist = decoded?.roles?.includes('blacklist:data:data-blacklist:view');

    const {onOpen} = useDisclosure();

    const menuItems = [
        showAuth && {
            href: `${process.env.REACT_APP_API_HOST}:3000/master`,
            imageSrc: userIcon,
            title: 'User Roles'
        },
        showWarehouse && {
            href: `${process.env.REACT_APP_API_HOST}:3001`,
            imageSrc: warehouseIcon,
            title: 'Warehouse'
        },
        showDashboard && {
            href: `${process.env.REACT_APP_API_HOST}:3002`,
            imageSrc: dashboardIcon,
            title: 'Dashboard'
        },
        showTeraTangki && {
            href: getAuthUrl(process.env.REACT_APP_TERRA, `${process.env.REACT_APP_API_HOST}:3003/terra`),
            imageSrc: teraIcon,
            title: 'Terra System'
        },
        showBlacklist && {
            href: getAuthUrl(process.env.REACT_APP_BL,`${process.env.REACT_APP_API_HOST}:3004/blacklist`),
            imageSrc: blacklistIcon,
            title: 'Blacklist System'
        },
        showGeneratedNRP && {
            href: 'http://ops.sinaralam.com/akerp/content/erp/id.php',
            imageSrc: nrpIcon,
            title: 'Generate NRP'
        }
    ].filter(Boolean);

    return (
        <Box
            float="center"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            bg={useColorModeValue('gray.50', 'gray.900')}
            display="flex"
            flexDirection="column"
        >
            <Portal>
                <Box>
                    <Navbar onOpen={onOpen} fixed={fixed} {...rest} />
                </Box>
            </Portal>

            <MotionBox
                variants={containerVariants}
                initial="hidden"
                animate="show"
                width="100%"
                textAlign="center"
                mt="120px"
                mb="20px"
            >
                <Heading
                    size="lg"
                    color={useColorModeValue('gray.700', 'white')}
                >
                    Welcome to Super App
                </Heading>
            </MotionBox>

            <MotionContainer 
                maxW="container.lg" 
                flex="1"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                px={4}
            >
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={{ base: 4, md: 6, lg: 8 }}
                    mx="auto"
                    maxW="900px"
                >
                    {menuItems.map((item, index) => (
                        <MenuCard
                            key={index}
                            href={item.href}
                            imageSrc={item.imageSrc}
                            title={item.title}
                        />
                    ))}
                </Box>
            </MotionContainer>

            <Box width="100%">
                <Footer/>
            </Box>
        </Box>
    );
}
