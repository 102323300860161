import React, {useState} from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast,
    Image,
    Grid,
    VStack,
    Heading,
    Container,
} from "@chakra-ui/react";
import SignUpModal from "../../../components/signup/signup";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";
import axios from "axios";
import Footer from "../../../components/footer/FooterAdmin";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionImage = motion(Image);

function SignIn() {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {isOpen, onClose} = useDisclosure();

    const handleLogin = async () => {
        if (!email || !password) {
            toast({
                title: "Validation Error",
                description: "Please fill in all required fields",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/v1/login',
                { email, password }
            );

            const token = response.data.data.token;
            const user = response.data.data.name;

            document.cookie = `token=${token}; path=/`;
            document.cookie = `user=${JSON.stringify(user)}; path=/`;

            if (token && user) {
                toast({
                    title: "Login successful",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
                window.location.href = "/admin";
            } else {
                if (response.data.code === 200) {
                    toast({
                        title: "Login failed , Call your IT Team",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                    });
                }
            }
        } catch (error) {
            toast({
                title: "Login failed",
                description: error.response?.data?.message || "An error occurred. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <MotionFlex
            maxW="100%"
            w="100%"
            mx="auto"
            minH="100vh"
            alignItems="center"
            justifyContent="center"
            px={{ base: "15px", sm: "25px", md: "45px" }}
            py={{ base: "15px", sm: "25px", md: "45px" }}
            flexDirection="column"
            bgGradient="linear(to-b, blue.50, white)"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}>
            
            <MotionFlex
                zIndex="2"
                direction="column"
                w={{ base: "100%", sm: "420px" }}
                maxW="100%"
                bg="white"
                boxShadow="2xl"
                borderRadius="xl"
                mx="auto"
                mb={{ base: "15px", md: "25px" }}
                p={{ base: "20px", md: "40px" }}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}>
                
                <VStack spacing={6}>
                    <MotionImage
                        src={`${process.env.PUBLIC_URL}/logosinaralam.png`}
                        alt="Logo Sinar Alam"
                        maxW="180px"
                        w="100%"
                        h="auto"
                        initial={{ scale: 0.8 }}
                        animate={{ scale: 1 }}
                        transition={{ delay: 0.3, duration: 0.5 }}
                    />
                    
                    <Heading size="lg" color={textColor} textAlign="center">
                        Welcome to Super App
                    </Heading>
                    
                    <Text color="gray.500" fontSize="md" textAlign="center">
                        Sign in to access your account
                    </Text>
                    
                    <FormControl>
                        <FormLabel
                            fontSize='sm'
                            fontWeight='600'
                            color={textColor}
                            mb='2'>
                            Email<Text as="span" color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            variant='filled'
                            fontSize='md'
                            type='email'
                            placeholder='Enter your email'
                            mb='4'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyPress={handleKeyPress}
                            _hover={{ bg: "gray.100" }}
                            _focus={{ borderColor: "blue.400", bg: "white" }}
                            transition="all 0.2s"
                        />
                        
                        <FormLabel
                            fontSize='sm'
                            fontWeight='600'
                            color={textColor}
                            mb='2'>
                            Password<Text as="span" color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size='md'>
                            <Input
                                variant='filled'
                                fontSize='md'
                                type={show ? "text" : "password"}
                                placeholder='Enter your password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyPress={handleKeyPress}
                                _hover={{ bg: "gray.100" }}
                                _focus={{ borderColor: "blue.400", bg: "white" }}
                                transition="all 0.2s"
                            />
                            <InputRightElement>
                                <Icon
                                    color={textColorSecondary}
                                    cursor="pointer"
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                    _hover={{ color: "blue.500" }}
                                />
                            </InputRightElement>
                        </InputGroup>
                        
                        <Button
                            mt="6"
                            fontSize='md'
                            fontWeight='600'
                            w='100%'
                            h='50px'
                            type="submit"
                            isLoading={isLoading}
                            onClick={handleLogin}
                            bgGradient="linear(to-r, blue.400, blue.600)"
                            color="white"
                            _hover={{
                                bgGradient: "linear(to-r, blue.500, blue.700)",
                                transform: "translateY(-2px)",
                                boxShadow: "lg"
                            }}
                            transition="all 0.2s">
                            Sign In
                        </Button>
                    </FormControl>
                </VStack>
            </MotionFlex>
            <SignUpModal isOpen={isOpen} onClose={onClose} />
            <Container maxW="container.xl" py={8}>
                <MotionFlex
                    direction="column"
                    bg="white"
                    boxShadow="xl"
                    borderRadius="xl"
                    overflow="hidden"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.5 }}>
                    
                    <Grid
                        templateColumns={{
                            base: "repeat(4, 1fr)",
                            sm: "repeat(4, 1fr)",
                            md: "repeat(10, 1fr)",
                            lg: "repeat(10, 1fr)"
                        }}
                        gap={4}
                        p={6}
                        justifyItems="center"
                        alignItems="center">
                        {[
                            "CTUM", "DBT", "DML", "DMLD", "DPL", "gmkl_HD", "KPL", "MJL",
                            "SADP", "SADP2", "SAL", "SAP", "SMPT", "TSL", "BPR", "CBAL",
                            "CBML", "CMPLSSML", "CPEL", "CPKA"
                        ].map((logo, index) => (
                            <MotionImage
                                key={logo}
                                src={`${process.env.PUBLIC_URL}/LogoAll/${logo}.png`}
                                alt={`Logo ${logo}`}
                                maxW={{ base: "55px", sm: "60px", md: "65px" }}
                                h="auto"
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{ delay: 0.1 * index, duration: 0.3 }}
                                whileHover={{ scale: 1.1 }}
                                fallbackSrc="https://via.placeholder.com/65"
                            />
                        ))}
                    </Grid>
                </MotionFlex>
            </Container>

            <Footer />
            
        </MotionFlex>
    );
}

export default SignIn;
